@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:'Readex Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#202023;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-scroll-bar {
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.gradient-btn{
  background:linear-gradient(89.64deg, #E6B903 -1.29%, #F5D882 98.65%);
}


.rotate-svg {
  animation: rotate 2s linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bg-custom-gradient {
  background: linear-gradient(90deg, #FF6A97 0%, #A2CDFF 100%);
}